import $ from 'jquery';
import utils from "utils/utils";


export default class CommonMethod {
    constructor({context, navName = '#nav-content-fixed', judgeNavToFixedEleName}) {
        this.context = context;

        this.navName = navName;
        this.judgeNavToFixedEleName = judgeNavToFixedEleName;
        
        // 存储a标签锚点
        this.anchorPointObjArr = [];

        // 导航高度
        this.navContentHeight = 0;

        // 导航初始y值
        this.navContentInitY = 0;

        // 当前屏幕分类
        this.screenTypeArr = [700, 900, 1200];

        // 当前屏幕类型
        this.screenType = 4;
    }

    init() {
        if (this.navName) {
            this.initAnchorPointObjArr();

            this.initNavContentY();

            this.showAnchorContentEvt();
        }

        this.initScreenType();

        this.watchScreenView();

        this.limitMap = new Map()
        .set("once11", true)
        .set("once22", true)
        .set("once33", true)
        .set("once44", true);
    }

    // 初始化屏幕类型
    initScreenType() {
        // 移动端
        if (window.innerWidth < this.screenTypeArr[0]) {
            this.screenType = 1;
        } 
        // ipad端（768）
        else if (window.innerWidth >= this.screenTypeArr[0] && window.innerWidth < this.screenTypeArr[1]) {
            this.screenType = 2;
        }
        // ipad端（1024）
        else if (window.innerWidth >= this.screenTypeArr[1] && window.innerWidth < this.screenTypeArr[2]) {
            this.screenType = 3;
        }
        // 大屏
        else if (this.screenTypeArr[2] <= window.innerWidth) {
            this.screenType = 4;
        }

        console.log('当前屏幕类型为：'+ this.screenType);
    }


    //modelFadeInup控制每个模块的滑入动画，当页面下滑时会触发，但只会触发一次
    modelFadeInup(modelRefName, distance, animaName, loadAni = false) {
        const tempRefs = this.context.$refs[modelRefName];
        // console.log(utils.ifEnterViewPostion(tempRefs, distance));
        if (loadAni && !tempRefs.className.includes(animaName)) {
            tempRefs.className += ` ${animaName}`;
            return;
        }

        if (utils.ifEnterViewPostion(tempRefs, distance) &&!tempRefs.className.includes(animaName)) {
            tempRefs.className += ` ${animaName}`;
            if (modelRefName == "joinMmPc") {
                let self = this.context;
                setTimeout(function () {
                    self.$refs.littleCube3.className += " littleCube1AnimationLeft";
                    self.$refs.littleCube4.className += " littleCube1AnimationRight";
                }, 700);
                setTimeout(function () {
                    self.$refs.littleCube1.className += " littleCube1AnimationLeft";
                    self.$refs.littleCube5.className += " littleCube1AnimationRight";
                }, 800);
                setTimeout(function () {
                    self.$refs.littleCube2.className += " littleCube1AnimationLeft";
                    self.$refs.littleCube6.className += " littleCube1AnimationSpecial";
                }, 900);
            }
        }
    }

    // 移动端模块动画（只执行一次）
    modelFadeInupMobile({id, autoPlay = false, aniName = 'weakFadeInUp'}) {
        const 
        $obj = $(`#${id}`),
        obj = $obj[0],
        showProcess = 0.2,
        top = Math.abs(obj && obj.getBoundingClientRect().top);


        if (autoPlay) {
            $obj.addClass(aniName);
        } else if (top < innerHeight - (innerHeight * showProcess) && !$obj.hasClass(aniName)) {
            $obj.addClass(aniName);
        }
    }



    //cubeAnimation控制页面中大型方块的动画，页面上移或下滑都会触发
    cubeAnimation(
        cubeRefName,
        distance,
        limitTime,
        moveDownAnima,
        moveUpAnima
    ) {
        if (!this.limitMap.get(limitTime)) return;
        const tempRefs = this.context.$refs[cubeRefName];
        if (
            utils.ifEnterViewPostion(tempRefs, distance) == "false" &&
            tempRefs.className.includes(moveDownAnima)
        ) {
            tempRefs.className += ` ${moveUpAnima}`;
            setTimeout(  () => {
                tempRefs.className = tempRefs.className.replace(
                    new RegExp("(\\s|^)" + moveUpAnima + "(\\s|$)"),
                    " "
                );
                tempRefs.className = tempRefs.className.replace(
                    new RegExp("(\\s|^)" + moveDownAnima + "(\\s|$)"),
                    " "
                );
                this.limitMap.set(limitTime, true);
            }, 1000);
            this.limitMap.set(limitTime, false);
        } else if (
            utils.ifEnterViewPostion(tempRefs, distance) == "true" &&
            !tempRefs.className.includes(moveDownAnima)
        ) {
            tempRefs.className += ` ${moveDownAnima}`;
            this.limitMap.set(limitTime, false);
            setTimeout( () => {
                this.limitMap.set(limitTime, true);
            }, 1000);
        }
    }

    // 初始化a标签锚点对象
    initAnchorPointObjArr() {
        const parentObj = document.querySelector(this.navName);
        if(!parentObj) return
        this.anchorPointObjArr = parentObj.querySelectorAll('a');
    }
    
    // 初始化导航Y值
    initNavContentY() {
        if(!document.querySelector(this.navName) || !document.querySelector(this.navName)) return
        const currentScrollTop = document.querySelector(this.navName).getBoundingClientRect().y;
        const navHeight = document.querySelector(this.navName).getBoundingClientRect().height;
        this.navContentInitY = currentScrollTop;
        this.navContentHeight = navHeight;
        // console.log(navHeight);
    }

    // 判断导航是不是到顶部了
    navIsTop() {
        const 
        sonNavElement = document.querySelector(this.judgeNavToFixedEleName),
        {top} = sonNavElement.getBoundingClientRect();

        if (top <= 0) {
            // console.log('到达顶部');
            return true;
        } else {
            return false;
        }
    }

    // 占据屏幕比例大就高亮
    showAnchorContentEvt() {
        let highligthId;
        let maxRatio = 0;
        const windowHeight = innerHeight;
        this.anchorPointObjArr.forEach(element => {
            const id = element.hash.slice(1)
            const target = document.getElementById(id)
            if (target) {
                let visibleRatio = 0;
                let {
                    top,
                    height,
                    bottom
                } = target.getBoundingClientRect();
                // 当元素全部可见时
                if (top >= 0 && bottom <= windowHeight) {
                    visibleRatio = height / windowHeight
                }
                // 当元素就头部可见时
                if (top >= 0 && top < windowHeight && bottom > windowHeight) {
                    visibleRatio = (windowHeight - top) / windowHeight
                }
                // 当元素占满屏幕时
                if (top < 0 && bottom > windowHeight) {
                    visibleRatio = 1
                }
                // 当元素尾部可见时
                if (top < 0 && bottom > 0 && bottom < windowHeight) {
                    visibleRatio = bottom / windowHeight
                }
                if (visibleRatio >= maxRatio) {
                    maxRatio = visibleRatio;
                    highligthId = id;
                }
            }
        });
        if (highligthId) {
            this.updateHeadText(highligthId)
        }
    }

    // 滚动时更新标题
    updateHeadText(id) {
        this.anchorPointObjArr.forEach(element => {
            element.classList.remove('active')
            if (element.hash.slice(1) == id) {
                element.classList.add('active')
            }
        });
    }

    // 判断是不是滚动到底部了
    judeScrollBottom() {
        let flag = false;
        var clientHeight = document.documentElement.scrollTop === 0 ? document.body.clientHeight : document.documentElement.clientHeight;
        var scrollTop = document.documentElement.scrollTop === 0 ? document.body.scrollTop : document.documentElement.scrollTop;
        var scrollHeight = document.documentElement.scrollTop === 0 ? document.body.scrollHeight : document.documentElement.scrollHeight;
        if (scrollTop != 0 && clientHeight + scrollTop == scrollHeight) {
            // console.log('‘到达底部’')
            flag = true;
        } else {
            flag = false;
        }
        return flag;
    }


    // 观察视口变化
    watchScreenView() {
        window.onresize = () => {
            let type
            if (window.innerWidth < this.screenTypeArr[0]) {
                type = 1;
            } 
            // ipad端（768）
            else if (window.innerWidth >= this.screenTypeArr[0] && window.innerWidth < this.screenTypeArr[1]) {
                type = 2;
            }
            // ipad端（1024）
            else if (window.innerWidth >= this.screenTypeArr[1] && window.innerWidth < this.screenTypeArr[2]) {
                type = 3;
            }
            // 大屏
            else if (this.screenTypeArr[2] <= window.innerWidth) {
                type = 4;
            }

            if (type === this.screenType) {
                // console.log('屏幕尺寸改变，在范围内');
            } else {
                window.location.reload();
                // console.log(type);
            }
        }
    }
    
    // 判断是不是ipad
    isIpad() {
        var ua = navigator.userAgent.toLowerCase();
        var s;
        s = ua.match(/iPad/i);
        if( s=="ipad" ) {
            return true;
        } else{
            return false;
        }
    }
}